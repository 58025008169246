
@media screen and (max-width: 2500px) {
  .adjust-text {
    padding-top: 15px;
    font-size: 31px;
  }
}

@media screen and (max-width: 2300px) {
  .adjust-text {
    padding-top: 15px;
    font-size: 29px;
  }
}

@media screen and (max-width: 2200px) {
  .adjust-text {
    padding-top: 15px;
    font-size: 27px;
  }
}

@media screen and (max-width: 2000px) {
  .adjust-text {
    padding-top: 15px;
    font-size: 25px;
  }
}

@media screen and (max-width: 1920px) {
  .adjust-text {
    padding-top: 15px;
    font-size: 24px;
  }
}

@media screen and (max-width: 1640px) {
  .adjust-text {
    padding-top: 14px;
    font-size: 23px;
  }
}

@media screen and (max-width: 1540px) {
  .adjust-text {
    padding-top: 14px;
    font-size: 20px;
  }
}

@media screen and (max-width: 1240px) {
  .adjust-text {
    padding-top: 14px;
    font-size: 17px;
  }
}


/* @media screen and (max-width: 1366px) {
  .adjust-padding {
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media screen and (max-width: 1280px) {
  .adjust-padding {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .adjust-padding {
    padding-left: 35px;
    padding-right: 35px;
  }
} */
